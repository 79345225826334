import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const RootComponent = (props) => {
  return (
    <React.StrictMode>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      >
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  );
};

if (process.env.NODE_ENV !== "production") {
  ReactDOM.render(<RootComponent />, document.getElementById("root"));
} else {
  const targets = document.querySelectorAll(".homeprice-form-root");
  Array.prototype.forEach.call(targets, (target) => {
    const id = target.dataset.id;
    const settings = { ...window.homepriceForm[id], id };
    ReactDOM.render(<RootComponent settings={settings} />, target);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
